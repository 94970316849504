/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checked_1': {
    width: 13,
    height: 9,
    viewBox: '0 0 13 9',
    data: '<path pid="0" d="M11.962 0L4.226 7.102 1.184 4.306.138 5.25 4.226 9 13 .952 11.962 0z" _fill="#8261FC"/>'
  }
})
